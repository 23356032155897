<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search Responsibility"
          type="text"
        />
        <b-button
          v-b-modal.applicationResModal
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Add Responsibility
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              dropright
              no-caret
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item :to="`/programs/assignment/${props.row.id}`">
                <feather-icon
                  class="mr-50"
                  icon="EyeIcon"
                />
                <span>View Review</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="selectedRow = props.row.originalIndex; $bvModal.show('partnerModal')"
              >
                <feather-icon
                  class="mr-50"
                  icon="UsersIcon"
                />
                <span>Assign Team</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="selectedRow = props.row.originalIndex; $bvModal.show('startupModal')"
              >
                <feather-icon
                  class="mr-50"
                  icon="ListIcon"
                />
                <span>Assign Partner</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="applicationResModal"
      size="lg"
      title="Review Form"
      ok-title="Save"
      @ok="addAssignment"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Title"
            label-size="sm"
          >
            <b-form-input
              v-model="responsibilityTitle"
              type="text"
              placeholder="Responsibility Title"
              required
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Enter Description"
            label-for="Enter Description"
          >
            <b-form-textarea
              v-model="description"
              type="text"
              placeholder="Enter Description"
              required
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Division"
            label-size="sm"
          >
            <b-form-input
              v-model="division"
              type="text"
              placeholder="Division"
              required
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Type"
            label-size="sm"
          >
            <v-select
              v-model="type"
              :normalizer="normalizer"
              :options="operations"
              placeholder="Select"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Priority Level"
            label-size="sm"
          >
            <v-select
              v-model="priorityLevel"
              :normalizer="normalizer"
              :options="['Urgent','High','Medium','Low']"
              placeholder="Select"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <transition-group name="list">
            <b-row
              v-for="(merit, idx) in merits"
              :key="merit.id"
            >
              <b-col>
                <b-form-group
                  :label="`Add Merit ${idx+1}`"
                  :label-for="`areaTitle-${idx+1}`"
                >
                  <b-form-input
                    :id="`areaTitle-${idx+1}`"
                    v-model="merit.title"
                    type="text"
                    placeholder="Add Merit"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-button
                  v-if="idx === merits.length - 1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2 mt-2"
                  @click="merits.push({
                    id:Math.random(),
                    title: '',
                  })"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Add Merit</span>
                </b-button>
                <b-button
                  v-if="merits.length > 1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mt-2"
                  @click="merits.splice(idx, 1)"
                >
                  <feather-icon
                    class="mr-25"
                    icon="XIcon"
                  />
                  <span>Remove</span>
                </b-button>
              </b-col>
            </b-row>
          </transition-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton, BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BPagination, BRow, BFormTextarea,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    // FormBuilder,
    vSelect,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      merits: [
        {
          id: 1,
          title: '',
        },
      ],
      items: [{
        id: 1,
        question: '',
        input_type: '',
        prevHeight: 0,
      }],
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Responsibility',
          field: 'title',
        },
        {
          label: 'Division',
          field: 'capacity',
        },
        {
          label: 'Type',
          field(row) {
            if (!row.programs_operationstable) return '-'
            return row.programs_operationstable.title || '-'
          },
        },
        {
          label: 'Priority Level',
          field(row) {
            if (!row.programs_operationstable) return '-'
            return row.programs_operationstable.title || '-'
          },
        },
        {
          label: 'Status',
          field(row) {
            if (!row.programs_operationstable) return '-'
            return row.programs_operationstable.operations_type || '-'
          },
        },
      ],
      rows: [],
      responsibilityTitle: null,
      description: null,
      division: null,
      type: null,
      priorityLevel: null,
    }
  },
  created() {
    this.$http.get('/events')
      .then(res => {
        this.rows = res.data
      })
  },
}
</script>
