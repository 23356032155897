<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          name="milestoneAddForm"
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >
          <!-- Resource Group tab -->
          <tab-content
            :before-change="validationFormInfo"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                />
                <b-col md="12">
                  <b-row
                    class="mb-2"
                  >
                    <b-col md="12">
                      <b-form-group
                        label="Select Reward"
                        label-for="reward"
                      >
                        <v-select
                          :options="rewards"
                          :reduce="reward => reward.id"
                          placeholder="Choose Reward"
                          @input="showRewardForm"
                          @change="showRewardForm"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="viewResponsibility"
                      cols="12"
                    >
                      <b-row class="ml-25 border-left">
                        <b-col
                          class="my-1"
                          cols="12"
                        >
                          <b-row>
                            <b-col
                              cols="auto"
                            ><h6 class="mt-25">
                              reward Input Form
                            </h6></b-col>
                            <b-col>
                              <hr>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Title"
                            label-size="sm"
                          >
                            <b-form-input
                              v-model="responsibilityTitle"
                              type="text"
                              placeholder="Responsibility Title"
                              required
                            /></b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Enter Description"
                            label-for="Enter Description"
                          >
                            <b-form-textarea
                              v-model="description"
                              type="text"
                              placeholder="Enter Description"
                              required
                              rows="3"
                              max-rows="6"
                            /></b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Division"
                            label-size="sm"
                          >
                            <b-form-input
                              v-model="division"
                              type="text"
                              placeholder="Division"
                              required
                            /></b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Type"
                            label-size="sm"
                          ><v-select
                            v-model="type"
                            :normalizer="normalizer"
                            :options="operations"
                            placeholder="Select"
                          /></b-form-group></b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Priority Level"
                            label-size="sm"
                          ><v-select
                            v-model="priorityLevel"
                            :normalizer="normalizer"
                            :options="['Urgent','High','Medium','Low']"
                            placeholder="Select"
                          /></b-form-group></b-col>
                        <b-col md="12">
                          <transition-group name="list">
                            <b-row
                              v-for="(merit, idx) in merits"
                              :key="merit.id"
                            >
                              <b-col>
                                <b-form-group
                                  :label="`Add Merit ${idx+1}`"
                                  :label-for="`areaTitle-${idx+1}`"
                                >
                                  <b-form-input
                                    :id="`areaTitle-${idx+1}`"
                                    v-model="merit.title"
                                    type="text"
                                    placeholder="Add Merit"
                                    required
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col cols="auto">
                                <b-button
                                  v-if="idx === merits.length - 1"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2 mt-2"
                                  @click="merits.push({
                                    id:Math.random(),
                                    title: '',
                                  })"
                                >
                                  <feather-icon
                                    icon="PlusIcon"
                                    class="mr-25"
                                  />
                                  <span>Add Merit</span>
                                </b-button>
                                <b-button
                                  v-if="merits.length > 1"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="danger"
                                  class="mt-2"
                                  @click="merits.splice(idx, 1)"
                                >
                                  <feather-icon
                                    class="mr-25"
                                    icon="XIcon"
                                  />
                                  <span>Remove</span>
                                </b-button>
                              </b-col>
                            </b-row></transition-group></b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BButton, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@core/utils/validations/validations'

export default {
  components: {
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormTextarea,
  },

  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      merits: [
        {
          id: 1,
          title: '',
        },
      ],
      required,
      rewards: [
        {
          id: 1,
          label: 'New Reward',
        },
        {
          id: 2,
          label: 'API Data 1',
        },
        {
          id: 3,
          label: 'API Data 2',
        },
      ],
      viewResponsibility: false,
      responsibilityTitle: null,
      description: null,
      division: null,
      type: null,
      priorityLevel: null,
    }
  },
  methods: {
    notifyParent() {
      this.$emit('input', this.sections)
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    showRewardForm(SelectType) {
      if (SelectType === null) {
        this.viewResponsibility = false
      } else {
        this.viewResponsibility = true
      }
    },
  },

}
</script>

<style lang="scss">
.vs__dropdown-menu{
  z-index: 1051 !important;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
