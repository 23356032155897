<template>
  <section id="card-navigation">
    <b-row id="app">
      <!-- pill tabs inside card -->
      <b-col md="14">
        <b-overlay
          :show="$apollo.loading"
          spinner-variant="primary"
        >
          <b-card
            class="text-center"
          >
            <b-card-header
              class="justify-content-start p-0 pb-1 mb-1 border-bottom"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2">
                Manage {{ program.title }}
              </h3>
            </b-card-header>
            <b-tabs
              nav-class="mb-3"
              pills
            >
              <b-tab
                active
                class="text-left"
                lazy
                title="Performance"
              >
                <performance />
              </b-tab>
              <b-tab
                class="text-left"
                lazy
                title="Responsibility"
              >
                <responsbility />
              </b-tab>

              <b-tab
                title="Attendance"
                lazy
              />
              <b-tab
                title="Leaves"
                lazy
                class="text-left"
              >
                <b-card
                  class="mt-2"
                  title="Leaves"
                />
              </b-tab>
              <b-tab
                class="text-left"
                lazy
                title="Reviews"
              >
                <review />
              </b-tab>
              <b-tab title="Payroll">
                <b-card
                  class="text-left"
                  title="Payroll"
                />
              </b-tab>
              <b-tab title="Requests">
                <b-card-title>Special title treatment</b-card-title>
                <b-card-text>
                  With supporting text below as a natural lead-in to additional content
                </b-card-text>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Go home
                </b-button>
              </b-tab>
              <b-tab title="Expenses">
                <b-card-title>Special title treatment</b-card-title>
                <b-card-text>
                  With supporting text below as a natural lead-in to additional content
                </b-card-text>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Go home
                </b-button>
              </b-tab>
              <b-tab title="Rewards">
                <Reward />
              </b-tab>
              <b-tab
                title="Communications"
                size="sm"
              >
                <b-card-title>Special title treatment</b-card-title>
                <b-card-text>
                  With supporting text below as a natural lead-in to additional content
                </b-card-text>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Go home
                </b-button>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BRow,
  BTab,
  BTabs,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { reactive } from '@vue/composition-api'
import gql from 'graphql-tag'
// import HorizontalScroll from 'vue-horizontal-scroll'
// import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
import Review from './review.vue'
import Responsbility from './responsbility.vue'
import Reward from './rewards.vue'
import Performance from './performance.vue'

export default {
  name: 'App',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BTab,
    BTabs,
    BCardText,
    BCardTitle,
    BButton,
    BOverlay,
    // HorizontalScroll,
    Review,
    Responsbility,
    Reward,
    Performance,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      program: '',
      applicationData: '',
      participantData: '',
      phaseData: '',
      subPhaseData: '',
      operationId: [],
      activityData: '',
      subPhaseId: [],

      // pvent: document.createEvent('Pvent'),
      // test: [{ operations_id: { _eq: 11 } }, { operations_id: { _eq: 74 } }],
    }
  },
  setup() {
    const participant = reactive({
      rounds: [
        { title: null },
      ],
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Application',
          field(row) {
            if (!row.programs_applicantstable) return '-'
            return row.programs_applicantstable.name
          },
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    })

    return {
      participant,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  watch: {
    phaseData(newValue, oldValue) {
      if (newValue !== oldValue) {
        sessionStorage.setItem('phaseData', JSON.stringify(this.phaseData))
        for (let i = 0; i < this.phaseData.length; i += 1) {
          this.operationId.push({ operations_id: { _eq: this.phaseData[i].id } })
        }
        this.$apollo.queries.subPhaseData.refresh()
      }
      const pvent = document.createEvent('Event')
      pvent.initEvent('storage', true, true)
      window.dispatchEvent(pvent)
    },
    subPhaseData(newValue, oldValue) {
      if (newValue !== oldValue) {
        sessionStorage.setItem('subPhaseData', JSON.stringify(this.subPhaseData))
        for (let i = 0; i < this.subPhaseData.length; i += 1) {
          this.subPhaseId.push({ operations_id: { _eq: this.subPhaseData[i].id } })
        }
        this.$apollo.queries.activityData.refresh()
      }
      const pvent = document.createEvent('Event')
      pvent.initEvent('storage', true, true)
      window.dispatchEvent(pvent)
    },
    activityData(newValue, oldValue) {
      if (newValue !== oldValue) {
        sessionStorage.setItem('activityData', JSON.stringify(this.activityData))
      }
      const pvent = document.createEvent('Event')
      pvent.initEvent('storage', true, true)
      window.dispatchEvent(pvent)
    },
    participantData(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.participant.rows = this.participantData
      }
    },
    program(newValue, oldValue) {
      if (newValue !== oldValue) {
        sessionStorage.setItem('programData', JSON.stringify(this.program))
      }
    },
  },
  apollo: {
    program: {
      query() {
        return gql`
        {
        programs_basicinfo(where: {id: {_eq: ${this.$route.params.id}}}) {
          id,
          title,
          begin_date,
          end_date,
          status,
          programs_partnerstables {
            networks_basicinfo {
              name
            }
          }
        }
      }`
      },
      update: data => data.programs_basicinfo[0],
    },
    phaseData: {
      query() {
        return gql`
        {
        programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}}) {
          id
          operations_type
          operations_id
          program_id
          begindate_timestamp
          enddate_timestamp
          title
        }
      }`
      },
      update: data => data.programs_operationstable,
    },
    subPhaseData: {
      query() {
        return gql`
        query($operationId: [programs_operationstable_bool_exp!]){
        programs_operationstable(where: {_or: $operationId}) {
          id
          operations_type
          operations_id
          program_id
          begindate_timestamp
          enddate_timestamp
          title
        }
      }`
      },
      variables() {
        return {
          operationId: this.operationId,
        }
      },
      update: data => data.programs_operationstable,
    },
    activityData: {
      query() {
        return gql`
        query($subPhaseId: [programs_operationstable_bool_exp!]){
        programs_operationstable(where: {_or: $subPhaseId}) {
          id
          operations_type
          operations_id
          program_id
          begindate_timestamp
          enddate_timestamp
          title
        }
      }`
      },
      variables() {
        return {
          subPhaseId: this.subPhaseId,
        }
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
